import { AuthConfiguration } from '../../configuration';

const defaultKeycloakQueryParams = [
  'kc_idp_hint',
  'kc_locale',
  'kc_authentication_flow',
];

export const prepareConfig = (
  authConfig: AuthConfiguration & { type: 'oidc' },
): AuthConfiguration & { type: 'oidc' } => {
  if (!window.location.search) {
    return authConfig;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const keys = authConfig.queryKeys || defaultKeycloakQueryParams;

  const extraQueryParams = keys.reduce<Record<string, string>>(
    (params, key) => {
      const value = urlParams.get(key);
      if (value) {
        params[key] = value;
      }
      return params;
    },
    {} as Record<string, string>,
  );

  if (Object.keys(extraQueryParams).length) {
    const originalQueryParams = (authConfig.settings.extraQueryParams ||
      {}) as Record<string, string>;
    authConfig.settings.extraQueryParams = {
      ...originalQueryParams,
      ...extraQueryParams,
    } as Record<string, string>;
  }

  return authConfig;
};
