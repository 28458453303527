import { OidcService } from '@fnz/oidc-authentication';

import { AuthConfiguration } from '../configuration/schemas/auth';
import { AuthService } from './auth.service';
import { NoAuth } from './no-auth/';
import { prepareConfig } from './oidc/utils';

export const getAuthorization = async (
  authConfig: AuthConfiguration,
): Promise<AuthService> => {
  const type = authConfig.type;
  switch (type) {
    case 'none':
      return NoAuth.create(authConfig);
    case 'oidc':
      const config = prepareConfig(authConfig);

      return OidcService.create({
        oidcSettings: config.settings,
        shouldRedirectToOriginUrl: !!config.shouldRedirectToOriginUrl,
        storage: config.storage,
      });
    default:
      throw new Error(
        `Authorization of type ${type as string} is not implemented`,
      );
  }
};
