import { JSONArray, JSONObject, JSONValue, MiddlewareFunc } from '../../types';
import {
  FETCH_MIDDLEWARE,
  FETCH_URL_KEY,
  FetchableValue,
  Middleware,
} from '../schemas/middleware';
import { fetchConfigurationData } from './utils';

const isValidEntry = (data: JSONValue): data is JSONObject =>
  typeof data === 'object' && !(data instanceof Array) && !!data;

export const isFetchableValue = (data: JSONValue): data is FetchableValue =>
  isValidEntry(data) &&
  FETCH_URL_KEY in data &&
  typeof data[FETCH_URL_KEY] === 'string';

type FetchableMiddleware = Middleware & {
  _middleware: typeof FETCH_MIDDLEWARE;
  configuration: FetchableValue['configuration'] & { url: string };
};

export const isFetchableMiddleware = (
  data: JSONValue,
): data is FetchableMiddleware => {
  return isValidEntry(data) && data['_middleware'] === FETCH_MIDDLEWARE;
};

const isArray = (val: JSONValue): val is JSONArray => {
  return val instanceof Array;
};
const isFetchable = (
  value: JSONValue,
): value is FetchableValue | FetchableMiddleware =>
  isValidEntry(value) &&
  (isFetchableMiddleware(value) || isFetchableValue(value));
const fetchOrReturn = async (
  value: JSONValue,
  token?: string,
): Promise<JSONValue> => {
  if (isValidEntry(value) && isFetchableMiddleware(value)) {
    return await fetchConfigurationData(value.configuration?.url, token);
  }
  if (isValidEntry(value) && isFetchableValue(value)) {
    return await fetchConfigurationData(value._url, token);
  }
  return value;
};
const isMerge = (value: JSONValue): boolean => {
  return (
    isValidEntry(value) &&
    isFetchable(value) &&
    value.configuration?.strategy === 'merge'
  );
};

export const fetchable = async (
  entry: JSONValue,
  token?: string,
): Promise<JSONValue> => {
  if (isArray(entry)) {
    let returnValue: JSONArray = [];
    for (const val of entry) {
      const fetchedData = await fetchOrReturn(val, token);
      const traversedData = await fetchable(fetchedData, token);
      if (isMerge(val) && isArray(traversedData)) {
        returnValue = [...returnValue, ...traversedData];
      } else {
        if (traversedData) {
          returnValue.push(traversedData);
        }
      }
    }
    return returnValue;
  }

  if (isValidEntry(entry) && !isFetchable(entry)) {
    const returnValue: JSONObject = {};
    for (const [key, val] of Object.entries(entry)) {
      const fetchedData = await fetchOrReturn(val, token);
      returnValue[key] = await fetchable(fetchedData, token);
    }
    return returnValue;
  }
  return fetchOrReturn(entry, token);
};

export const FetchableMiddleware: MiddlewareFunc = async (entry, options) => {
  const { token } = options || {};
  return (await fetchable(entry, token)) as JSONObject;
};
