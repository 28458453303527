import { DockContext } from '../../context';
import { JSONObject, MiddlewareFunc } from '../../types';
import { DockConfiguration, MiddlewareConfiguration } from '../index';
import { getMiddlewareDefinitions } from '../process-configuration';
import { FetchableMiddleware } from './fetchable';
import { templateMiddleware } from './template';
import { ValidateMiddleware } from './validate';

const deepJsonClone = (obj: JSONObject) => {
  if (window.structuredClone) {
    return window.structuredClone(obj);
  }
  return JSON.parse(JSON.stringify(obj)) as JSONObject;
};

const WrapperMiddleware: (
  middlewareConfig: MiddlewareConfiguration,
) => MiddlewareFunc = (middlewareConfig) => {
  return async (config, options, dockContext) => {
    const { middleware }: { middleware: MiddlewareFunc } = await System.import(
      middlewareConfig.id,
    );
    // middleware can mutate configuration (see template step)
    // we want to use fresh configuration. Generally it is not a good idea
    // and middleware should mind they own business and not touch other configs
    const freshConfig = (config as DockConfiguration).middleware?.find(
      (m) => m.id === middlewareConfig.id,
    );
    if (!freshConfig) {
      console.warn('middleware configuration was lost', middlewareConfig.id);
      return config;
    }
    return middleware(
      config,
      { ...freshConfig?.config, ...options },
      dockContext,
    );
  };
};
const wrapPipeline = (middleware: DockConfiguration['middleware']) => {
  return middleware?.map(WrapperMiddleware) || [];
};

const executePipeline = async (
  pipeline: MiddlewareFunc[],
  config: JSONObject,
  token: string,
  dockContext?: DockContext,
) => {
  let accumulator = deepJsonClone(config);
  for (const middleware of pipeline) {
    try {
      accumulator = deepJsonClone(
        await middleware(accumulator, { token }, dockContext),
      );
    } catch (e) {
      console.log(e);
    }
  }
  return accumulator;
};
export const runPrePipeline = async (
  config: JSONObject,
  middleware: DockConfiguration['middleware'],
  token: string = '',
) => {
  const middlewares = getMiddlewareDefinitions(middleware, 'preAuth');
  const pipeline = [templateMiddleware, ...wrapPipeline(middlewares)];
  return await executePipeline(pipeline, config, token);
};

export const runPipeline = async (
  middleware: DockConfiguration['middleware'],
  config: JSONObject,
  token: string,
  dockContext?: DockContext,
) => {
  const middlewares = getMiddlewareDefinitions(middleware, 'default');
  const pipeline = [
    FetchableMiddleware,
    ...wrapPipeline(middlewares),
    templateMiddleware,
  ];
  const accumulator = await executePipeline(
    pipeline,
    config,
    token,
    dockContext,
  );
  return await ValidateMiddleware(accumulator);
};
