import { DockConfiguration } from '.';

import { DockContext } from '../context';
import { JSONArray, JSONObject } from '../types';
import { addMicrofrontendsToImportMaps } from '../utility/add-microfrontends-to-import-maps';
import { runPipeline } from './middleware';
import { Utility, UtilitySchema } from './schemas/microfrontends';

export async function processConfiguration(
  config: JSONObject,
  accessToken: string,
  dockContext?: DockContext,
): Promise<DockConfiguration> {
  return runPipeline(
    config['middleware'] as DockConfiguration['middleware'],
    config,
    accessToken,
    dockContext,
  );
}

export const getMiddlewareDefinitions = (
  middleware: DockConfiguration['middleware'] = [],
  stage: 'preAuth' | 'default' = 'default',
) => {
  return middleware?.filter((middleware) => {
    if (middleware.stage) {
      return middleware.stage === stage;
    }
    return stage === 'default';
  });
};

export const registerMiddleware = (
  microfrontends: DockConfiguration['microfrontends'] = [],
  middleware: DockConfiguration['middleware'] = [],
  stage: 'preAuth' | 'default' = 'default',
) => {
  const ids = getMiddlewareDefinitions(middleware, stage).map(
    (middleware) => middleware.id,
  );
  const modules = getUtilityModuleDefinitions(microfrontends, ids);
  return addMicrofrontendsToImportMaps(modules);
};

export const getUtilityModuleDefinitions = (
  microfrontends: JSONArray,
  ids?: string[],
) => {
  return microfrontends
    .filter((mfe) =>
      ids?.length ? ids.includes((mfe as { id: string }).id) : true,
    )
    .map((mfe) => {
      const utility = UtilitySchema.safeParse(mfe);
      if (utility.success) {
        return utility.data;
      }
      return null;
    })
    .filter((mfe): mfe is Utility => mfe !== null);
};
